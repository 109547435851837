export default class Jogo {
    init() {
        window.Session.isLogged();
        this.data = $('span.data');
        this.local = $('span.local');
        this.grupo = $('span.grupo');
        this.nomeA = $('.equipe-a .nome');
        this.escudoA = $('.equipe-a .logo-equipe');
        this.nomeB = $('.equipe-b .nome');
        this.escudoB = $('.equipe-b .logo-equipe');
        this.placar = $('.placar');
        this.pontosA = 0;
        this.pontosB = 0;
        this.penaltiA = 0;
        this.penaltiB = 0;
        this.tabelaA = $('.tabela-a');
        this.tabelaB = $('.tabela-b');
        this.idJogo = window.Helper.get('id');
        this.golModal = null;
        this.bind();
    }

    bind() {
        let self = this;
        var request = window.APIService.get('afia-tools/buscar-jogo/' + self.idJogo);

        $('.loading').show();
        request.always(function (jogo) {
            $('.loading').hide();
            if (typeof jogo.id_jogo != 'undefined') {
                self.renderJogo(jogo);

                self.jogadoresTime = [];
                self.jogadoresTime['time' + jogo.time_a.id_time] = jogo.time_a.jogadores;
                self.jogadoresTime['time' + jogo.time_b.id_time] = jogo.time_b.jogadores;
            } else {
                self.jogo.append('<table class="table table-hover"><tr><td class="text-center"><i class="bx bxs-error-circle"></i> Nenhum jogo encontrado!</td></tr></table>');
            }
        });
    }

    renderJogo(jogo) {
        let self = this;

        // Data
        self.removePlaceholder(self.data);
        self.data.html(window.Helper.dateToHour(jogo.data_hora, 'date'));

        // Local
        self.removePlaceholder(self.local);
        self.local.html(jogo.campo);

        // Grupo
        self.removePlaceholder(self.grupo);
        self.grupo.html(jogo.grupo);

        // Nome Time A
        self.removePlaceholder(self.nomeA);
        let categoriaA = (typeof jogo.time_a.categoria.descricao != 'undefined') ? ' - ' + jogo.time_a.categoria.descricao : '';
        self.nomeA.html(jogo.time_a.nome_time.nome + categoriaA);

        // Escudo Time A
        self.removePlaceholder(self.escudoA);
        if (jogo.time_a.escudo != null) {
            self.escudoA.html(`<img src="${jogo.time_a.escudo}" alt="${jogo.time_a.nome_time.nome}">`);
        }

        // Nome Time B
        self.removePlaceholder(self.nomeB);
        let categoriaB = (typeof jogo.time_b.categoria.descricao != 'undefined') ? ' - ' + jogo.time_b.categoria.descricao : '';
        self.nomeB.html(jogo.time_b.nome_time.nome + categoriaB);

        // Escudo Time B
        self.removePlaceholder(self.escudoB);
        if (jogo.time_b.escudo != null) {
            self.escudoB.html(`<img src="${jogo.time_b.escudo}" alt="${jogo.time_b.nome_time.nome}">`);
        }

        // Placar
        self.pontosA = jogo.pontuacao_a;
        self.pontosB = jogo.pontuacao_b;
        self.penaltiA = jogo.penalti_a;
        self.penaltiB = jogo.penalti_b;
        self.atualizaPlacar();

        // Tabela A
        self.renderTabelaA(jogo);

        // Tabela B
        self.renderTabelaB(jogo);

        // Renderizar cartões
        self.renderizaCartoes(jogo);

        // Renderizar gols
        self.renderizaGols(jogo);
    }

    /**
     * Renderiza toda a tabela do time A
     * 
     * @param {object} jogo 
     */
    renderTabelaA(jogo) {
        let self = this;
        let time = jogo.time_a;
        let html = '';
        let numeroGol = 1;

        $.each(time.jogadores, function (indexJogador, jogador) {
            if (!jogador.excluido) {
                html += self.renderJogador(jogador, time, numeroGol);
                numeroGol++;
            }
        });

        self.tabelaA.append(html);
    }

    /**
     * Renderiza toda a tabela do time B
     * 
     * @param {object} jogo 
     */
    renderTabelaB(jogo) {
        let self = this;
        let time = jogo.time_b;
        let html = '';
        let numeroGol = 1;

        $.each(time.jogadores, function (indexJogador, jogador) {
            if (!jogador.excluido) {
                html += self.renderJogador(jogador, time, numeroGol);
                numeroGol++;
            }
        });

        self.tabelaB.append(html);
    }

    /**
     * Renderiza a linha com as informações de cada jogador
     * 
     * @param {object} jogador 
     * @param {object} time 
     * @param {int} numeroGol 
     * @returns 
     */
    renderJogador(jogador, time, numeroGol) {
        return `<tr id="jogador-${jogador.id_pessoa}">
                    <td class="text-center">${jogador.nr_camisa != null ? jogador.nr_camisa : '---'}</td>
                    <td>${jogador.nm_camisa}</td>
                    <td>
                        <select class="cartao-amarelo form-select" onchange="javascript:window.Jogo.cartaoAM('amarelo', ${time.id_time}, ${jogador.id_pessoa}, this.value);">
                            <option>0</option>
                            <option>1</option>
                            <option>2</option>
                        </select>
                    </td>
                    <td>
                        <select class="cartao-azul form-select" onchange="javascript:window.Jogo.cartaoAZ('azul', ${time.id_time}, ${jogador.id_pessoa}, this.value);">
                            <option>0</option>
                            <option>1</option>
                        </select>
                    </td>
                    <td>
                        <select class="cartao-vermelho form-select" onchange="javascript:window.Jogo.cartaoVM('vermelho', ${time.id_time}, ${jogador.id_pessoa}, this.value);">
                            <option>0</option>
                            <option>1</option>
                        </select>
                    </td>
                    <td width="6%" class="num-gol">${window.Helper.padLeft(numeroGol, 2)}</td>
                    <td width="15%" class="text-center gol seq${numeroGol}">
                        ---
                    </td>
                    <td width="8%" class="text-center botoes seq${numeroGol}">
                        <a href="#" onclick="javascript:window.Jogo.gerenciarGol(${numeroGol}, ${time.id_time}, null);" data-toggle="tooltip" data-placement="top" title="Informar Gol"><i class="bx bx-football bx-sm"></i></a> 
                    </td>
                </tr>`;
    }

    /**
     * Monta o select com base numa lista de jogadores
     * 
     * @param {array} jogadores
     * @returns {string} html com os options de cada jogador
     */
    montaSelect(jogadores) {
        let html = '';
        $.each(jogadores, function (indexJogador, jogador) {
            let numeroCamisa = (jogador.nr_camisa != null) ? jogador.nr_camisa + ' - ' : '';
            html += (jogador.nr_camisa != 'CT') ? `<option value="${jogador.id_pessoa}">${numeroCamisa + jogador.nm_camisa}</option>` : '';
        });
        return html;
    }

    /**
     * Renderiza os gols de cada time em suas respectivas tabelas
     * 
     * @param {object} jogo 
     */
    renderizaCartoes(jogo) {
        $.each(jogo.time_a.jogadores, function (i, jogador) {
            if (typeof jogador.jogador_jogo != 'undefined' && jogador.jogador_jogo != null) {
                if (jogador.jogador_jogo.cartao_amarelo > 0) {
                    $(`table.tabela-time-a tr#jogador-${jogador.id_pessoa} select.cartao-amarelo`).val(jogador.jogador_jogo.cartao_amarelo).addClass('selected');
                }
                if (jogador.jogador_jogo.cartao_azul > 0) {
                    $(`table.tabela-time-a tr#jogador-${jogador.id_pessoa} select.cartao-azul`).val(jogador.jogador_jogo.cartao_azul).addClass('selected');
                }
                if (jogador.jogador_jogo.cartao_vermelho > 0) {
                    $(`table.tabela-time-a tr#jogador-${jogador.id_pessoa} select.cartao-vermelho`).val(jogador.jogador_jogo.cartao_vermelho).addClass('selected');
                }
            }
        });

        $.each(jogo.time_b.jogadores, function (i, jogador) {
            if (typeof jogador.jogador_jogo != 'undefined' && jogador.jogador_jogo != null) {
                if (jogador.jogador_jogo.cartao_amarelo > 0) {
                    $(`table.tabela-time-b tr#jogador-${jogador.id_pessoa} select.cartao-amarelo`).val(jogador.jogador_jogo.cartao_amarelo).addClass('selected');
                }
                if (jogador.jogador_jogo.cartao_azul > 0) {
                    $(`table.tabela-time-b tr#jogador-${jogador.id_pessoa} select.cartao-azul`).val(jogador.jogador_jogo.cartao_azul).addClass('selected');
                }
                if (jogador.jogador_jogo.cartao_vermelho > 0) {
                    $(`table.tabela-time-b tr#jogador-${jogador.id_pessoa} select.cartao-vermelho`).val(jogador.jogador_jogo.cartao_vermelho).addClass('selected');
                }
            }
        });
    }

    /**
     * Renderiza o gol na sua respectiva linha
     * 
     * @param {string} tabela a ou b
     * @param {int} numeroGol
     * @param {object} gol
     */
    renderizaGol(tabela, numeroGol, gol) {
        // Informações do gol
        var golField = $('table.tabela-time-' + tabela + ' td.gol.seq' + (numeroGol + 1));
        golField.html(`${gol.jogador.nr_camisa} | ${gol.minuto}`);
        if (gol.penalti) {
            golField.append(` <sup data-toggle="tooltip" data-placement="top" title="Penalti"><i class='bx bxl-product-hunt'></i></sup>`);
            golField.addClass('penalti');
        }

        // Botão editar
        $('table.tabela-time-' + tabela + ' td.botoes.seq' + (numeroGol + 1)).html(`<a href="#" onclick="javascript:window.Jogo.gerenciarGol(${numeroGol + 1}, ${gol.id_time}, ${gol.id_gol});" data-toggle="tooltip" data-placement="top" title="Editar Gol"><i class="bx bxs-pencil bx-sm"></i></a>`);
    }

    limpaGol(tabela, idTime, numeroGol) {
        console.log('limpaGol', tabela, idTime, numeroGol);
        $('table.tabela-time-' + tabela + ' td.gol.seq' + (numeroGol + 1)).html('---');
        $('table.tabela-time-' + tabela + ' td.botoes.seq' + (numeroGol + 1)).html(`<a href="#" onclick="javascript:window.Jogo.gerenciarGol(${numeroGol + 1}, ${idTime}, null);" data-toggle="tooltip" data-placement="top" title="Informar Gol"><i class="bx bx-football bx-sm"></i></a>`);
    }

    /**
     * Renderiza os gols de cada time na sua respectiva tabela
     * 
     * @param {object} jogo 
     */
    renderizaGols(jogo) {
        let self = this;

        $.each(jogo.time_a.gols, function (numeroGol, gol) {
            if (typeof gol.id_gol != 'undefined') {
                self.renderizaGol('a', numeroGol, gol);
            }
        });

        $.each(jogo.time_b.gols, function (numeroGol, gol) {
            if (typeof gol.id_gol != 'undefined') {
                self.renderizaGol('b', numeroGol, gol);
            }
        });

        $('[data-toggle="tooltip"]').tooltip();
    }

    removePlaceholder(obj) {
        obj.removeClass('placeholder');
    }

    /**
     * Cadastrar cartão amarelo
     * 
     * @param {string} tipoCartao
     * @param {int} idTime
     * @param {int} idJogador
     * @param {int} qtde
     */
    cartaoAM(tipoCartao, idTime, idJogador, qtde) {
        let self = this;

        $('.loading').show();
        let request = window.APIService.post('afia-tools/cadastrar-cartao', {
            id_jogo: self.idJogo,
            id_time: idTime,
            id_jogador: idJogador,
            tipo_cartao: tipoCartao,
            qtde: qtde
        });

        request.always(function (cartao) {
            if (typeof cartao.jogador_jogo.id != 'undefined') {
                toastr.success('Cartao ' + tipoCartao + ' cadastrado com sucesso!');

                if (qtde > 0) {
                    $(`tr#jogador-${idJogador} select.cartao-${tipoCartao}`).addClass('selected');
                } else {
                    $(`tr#jogador-${idJogador} select.cartao-${tipoCartao}`).removeClass('selected');
                }
            } else {
                toastr.error('Ocorreu um erro ao cadastrar o cartão');
            }
            $('.loading').hide();
        });
    }

    /**
     * Cadastrar cartão azul
     * 
     * @param {string} tipoCartao
     * @param {int} idTime
     * @param {int} idJogador
     * @param {int} qtde
     */
    cartaoAZ(tipoCartao, idTime, idJogador, qtde) {
        let self = this;

        $('.loading').show();
        let request = window.APIService.post('afia-tools/cadastrar-cartao', {
            id_jogo: self.idJogo,
            id_time: idTime,
            id_jogador: idJogador,
            tipo_cartao: tipoCartao,
            qtde: qtde
        });

        request.always(function (cartao) {
            if (typeof cartao.jogador_jogo.id != 'undefined') {
                toastr.success('Cartao ' + tipoCartao + ' cadastrado com sucesso!');
                if (qtde > 0) {
                    $(`tr#jogador-${idJogador} select.cartao-${tipoCartao}`).addClass('selected');
                } else {
                    $(`tr#jogador-${idJogador} select.cartao-${tipoCartao}`).removeClass('selected');
                }
            } else {
                toastr.error('Ocorreu um erro ao cadastrar o cartão');
            }
            $('.loading').hide();
        });
    }

    /**
     * Cadastrar cartão vermelho
     * 
     * @param {string} tipoCartao
     * @param {int} idTime
     * @param {int} idJogador
     * @param {int} qtde
     */
    cartaoVM(tipoCartao, idTime, idJogador, qtde) {
        let self = this;

        $('.loading').show();
        let request = window.APIService.post('afia-tools/cadastrar-cartao', {
            id_jogo: self.idJogo,
            id_time: idTime,
            id_jogador: idJogador,
            tipo_cartao: tipoCartao,
            qtde: qtde
        });

        request.always(function (cartao) {
            if (typeof cartao.jogador_jogo.id != 'undefined') {
                toastr.success('Cartao ' + tipoCartao + ' cadastrado com sucesso!');
                if (qtde > 0) {
                    $(`tr#jogador-${idJogador} select.cartao-${tipoCartao}`).addClass('selected');
                } else {
                    $(`tr#jogador-${idJogador} select.cartao-${tipoCartao}`).removeClass('selected');
                }
            } else {
                toastr.error('Ocorreu um erro ao cadastrar o cartão');
            }
            $('.loading').hide();
        });
    }

    /**
     * Cadastrar, alterar e excluir gol
     * 
     * @param {int} numeroGol
     * @param {int} idTime
     * @param {int} idGol
     */
    gerenciarGol(numeroGol, idTime, idGol) {
        let self = this;

        Swal.fire({
            title: 'Informar Gol',
            html: `<form class="form-modal text-start p-1" action="#">
                    <div class="mb-3">
                        <label for="modal-jogador-gol" class="form-label">Jogador:</label>
                        <select class="form-select" id="modal-jogador-gol">
                            <option value="">--</option>
                            ${self.montaSelect(self.jogadoresTime['time' + idTime])}
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="modal-minuto-gol" class="form-label">Minuto Gol:</label>
                        <input type="tel" class="form-control" id="modal-minuto-gol" maxlength="5" autocomplete="off">
                    </div>
                    <div class="mb-3 form-check">
                        <input type="checkbox" class="form-check-input" id="modal-penalti">
                        <label class="form-check-label" for="modal-penalti">Penalti</label>
                    </div>
                  </form>`,
            confirmButtonText: `<i class='bx bxs-check-circle'></i> Salvar`,
            confirmButtonColor: '#283878',
            showCancelButton: true,
            cancelButtonText: `<i class='bx bxs-x-circle'></i> Cancelar`,
            showDenyButton: window.Helper.isNumeric(idGol),
            denyButtonText: `<i class='bx bxs-trash'></i> Excluir`,
            focusConfirm: false,
            didOpen: () => {
                $('#modal-minuto-gol').mask('ZAA:AA', {
                    translation: {
                        A: { pattern: /[0-9]/ },
                        B: { pattern: /[0-5]/ },
                        Z: { pattern: /[0-1]/, optional: true }
                    },
                    clearIfNotMatch: true,
                    reverse: true
                });
            },
            preConfirm: () => {
                const jogadorGol = Swal.getPopup().querySelector('#modal-jogador-gol').value;
                const minutoGol = Swal.getPopup().querySelector('#modal-minuto-gol').value;
                if (!window.Helper.isNumeric(jogadorGol) && !(minutoGol.length == 5 || minutoGol.length == 6)) {
                    Swal.showValidationMessage(`Informe o jogador e o minuto do gol`);
                }

                return {
                    jogadorGol: jogadorGol,
                    minutoGol: minutoGol,
                    penalti: $('#modal-penalti').is(':checked') ? 1 : 0
                }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                $('.loading').show();
                let request = window.APIService.post('afia-tools/cadastrar-gol', {
                    id_gol: idGol,
                    id_jogo: self.idJogo,
                    id_time: idTime,
                    id_jogador: result.value.jogadorGol,
                    minuto: result.value.minutoGol,
                    penalti: result.value.penalti
                });

                request.always(function (response) {

                    if (typeof response.gol != 'undefined') {

                        if (response.gol.jogo.time_a == idTime) {
                            if (result.value.penalti) {
                                self.penaltiA++;
                            } else {
                                self.pontosA++;
                            }
                        } else {
                            if (result.value.penalti) {
                                self.penaltiB++;
                            } else {
                                self.pontosB++;
                            }
                        }
                        self.atualizaPlacar();

                        self.renderizaGol((response.gol.jogo.time_a == idTime) ? 'a' : 'b', (numeroGol - 1), response.gol);
                        toastr.success(response.message);
                    } else {
                        toastr.error('Ocorreu um erro ao cadastrar o gol');
                    }

                    $('.loading').hide();
                });
            } else if (result.isDenied) {
                // Excluir Gol
                Swal.fire({
                    icon: 'question',
                    title: 'Deseja realmente excluir este gol?',
                    showCancelButton: true,
                    cancelButtonText: `<i class='bx bxs-x-circle'></i> Cancelar`,
                    confirmButtonText: `<i class='bx bxs-check-circle'></i> Sim`,
                    confirmButtonColor: '#dc3741',
                }).then((result) => {
                    if (result.isConfirmed) {
                        $('.loading').show();
                        let request = window.APIService.post('afia-tools/cancelar-gol', {
                            id_gol: idGol
                        });

                        request.always(function (response) {

                            if (typeof response.gol != 'undefined') {
                                self.limpaGol((self.golModal.jogo.time_a == idTime) ? 'a' : 'b', idTime, (numeroGol - 1));

                                if (self.golModal.jogo.time_a == idTime) {
                                    if (self.golModal.penalti) {
                                        self.penaltiA--;
                                    } else {
                                        self.pontosA--;
                                    }
                                } else {
                                    if (self.golModal.penalti) {
                                        self.penaltiB--;
                                    } else {
                                        self.pontosB--;
                                    }
                                }
                                self.atualizaPlacar();

                                toastr.success(response.message);
                            } else {
                                toastr.error('Ocorreu um erro ao excluir o gol');
                            }

                            $('.loading').hide();
                        });
                    }
                })
            }
        });

        // Enviar form ao clicar "Ir" teclado tablet
        $('.form-modal').on('submit', function (e) {
            e.preventDefault();
            Swal.clickConfirm();
        });

        if (window.Helper.isNumeric(idGol)) {
            var request = window.APIService.get('afia-tools/buscar-gol/' + idGol);

            $('.loading').show();
            request.always(function (gol) {
                $('.loading').hide();
                if (typeof gol.id_gol != 'undefined') {
                    self.golModal = gol;

                    $('#modal-jogador-gol').val(gol.id_jogador);
                    $('#modal-minuto-gol').val(gol.minuto);
                    $('#modal-penalti').prop('checked', gol.penalti == 1 ? true : false);
                } else {
                    Swal.close();
                    toastr.error('Ocorreu um erro ao buscar o gol');
                }
            });
        }
    }

    atualizaPlacar() {
        let self = this;
        let placar = (self.penaltiA > 0 || self.penaltiB > 0)
            ? `<span id="ponto-a">${self.pontosA}<sup>${self.penaltiA}</sup></span> x <span id="ponto-b"><sup>${self.penaltiB}</sup>${self.pontosB}</span>`
            : `<span id="ponto-a">${self.pontosA}</span> x <span id="ponto-b">${self.pontosB}</span>`;
        self.placar.html(placar);
    }
}