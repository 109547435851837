export default class Evento {
    init() {
        window.Session.isLogged();
        this.eventoForm = $('#evento-form');
        this.eventoSelect = $('#evento');
        toastr.options.positionClass = 'toast-top-center';
        this.bind();
    }

    bind() {
        let self = this;

        /* Listar eventos */
        var request = window.APIService.get('afia-tools/listar-eventos');
        $('.loading').show();
        request.always(function (eventos) {
            $('.loading').hide();

            if (eventos != null && eventos.length > 0) {
                let html = '';
                $.each(eventos, function (index, evento) {
                    html += '<option value="' + evento.id_evento + '">' + evento.ds_evento + '</option>';
                });
                $('#evento').append(html);
            } else {
                toastr.warning('Tivemos um problema ao listar os eventos disponíveis!');
            }
        });

        /* Selecionar evento */
        self.eventoForm.on('submit', function (e) {
            e.preventDefault();
            if (window.Helper.isNumeric(self.eventoSelect.val())) {
                window.Session.setEvento(self.eventoSelect.val());
                window.location.href = 'home.html';
            } else {
                toastr.warning('Selecione um evento!');
            }
        });

    }
}