export default class Cordova {
    
    init() {
        
        document.addEventListener('deviceready', function (e) {

            /**
             * Voltar tela ao clicar no botão voltar do dispositivo
             */
            document.addEventListener('backbutton', function (e) {
                window.history.back();
            }, false);

        }, false);
    }

}