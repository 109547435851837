export default class Home {
    init() {
        window.Session.isLogged();
        this.bind();
    }

    bind() {
        let self = this;

        
    }
}