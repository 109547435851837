export default class APIService {

    constructor() {
        this.server = 'https://api.ciatravel.com.br/v1/';
        this.headers = {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + window.Session.getToken(),
        };
        this.response = null;
    }

    _call(method, url, data) {
        let response = $.ajax({
            headers: this.headers,
            method: method,
            url: this.server + url,
            timeout: 80000,
            data: data
        });

        response.fail((jqXHR, textStatus, errorThrown) => {
            if (typeof jqXHR.status != 'undefined' && jqXHR.status == 401) {
                window.Session.destroySession();
                window.location.href = '/';
            } else if (typeof jqXHR.status != 'undefined' && jqXHR.status == 0) {
                Swal.fire({
                    title: 'Ops!',
                    text: 'Erro ao estabelecer conexão com o servidor!',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#032659',
                });
            }
        });

        return response;
    }

    get(url, data) {
        return this._call('GET', url, data);
    }

    post(url, data) {
        return this._call('POST', url, data);
    }
}