import Session from "./session";

export default class WineHouse {
    init() {
        this.loginForm = $('#login-form');
        this.loginButton = $('.btn-login');

        window.Session.isLogged(true);

        this.bind();
    }

    bind() {
        let self = this;

        self.loginForm.on('submit', function (e) {
            e.preventDefault();

            self.loginButton.attr('disabled', true);
            self.loginButton.html('<i class="bx bx-loader-alt bx-spin bx-rotate-180"></i> Login');
            var request = window.APIService.post('user/login', {
                usuario: $('#usuario').val(),
                senha: $('#senha').val()
            });

            request.always(function (usuario) {
                if (usuario != null && typeof usuario.token != 'undefined') {
                    window.Session.createSession(usuario.usuario.id_usuario, usuario.token);

                    window.location.href = 'evento.html';
                } else {
                    Swal.fire({
                        title: 'Ops!',
                        text: 'Usuário ou senha incorreta!',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#283878',
                    });
                }
                self.loginButton.html('Login');
                self.loginButton.attr('disabled', false);
            });
        });
    }
}