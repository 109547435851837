export default class Cartoes {
    init() {
        window.Session.isLogged();
        this.tableDestaques = $('.table-destaques');
        this.bind();
    }

    bind() {
        let self = this;

        /* Listar destaques */
        $('.loading').show();
        var request = window.APIService.get('afia-tools/cartoes/' + window.Session.getEvento());
        request.always(function (cartoes) {
            $('.loading').hide();
            if (cartoes != null && cartoes.length > 0) {
                self.renderDestaques(cartoes);
            } else {
                self.tableDestaques.append('<tr><td colspan="6" class="text-center"><i class="bx bxs-error-circle"></i> Nenhum cartão encontrado!</td></tr>');
            }
        });
    }

    /**
     * Renderiza os cartões na tabela
     * @param {JSON} cartoes Json com os cartões
     * @returns {void}
     * 
     */
    renderDestaques(cartoes) {
        let html = '';
        $.each(cartoes, function (index, cartao) {
            html += '<tr>';
            html += '<td>' + (index + 1) + '</td>';
            html += '<td>' + cartao.jogador.nome + '</td>';
            html += '<td>' + cartao.time.nome_time.nome + ' - ' + cartao.time.categoria.descricao + '</td>';
            html += '<td class="text-center"><span class="badge bg-danger">' + cartao.cartao_vermelho + '</span></td>';
            html += '<td class="text-center"><span class="badge bg-primary">' + cartao.cartao_azul + '</span></td>';
            html += '<td class="text-center"><span class="badge bg-warning">' + cartao.cartao_amarelo + '</span></td>';
            html += '</tr>';
        });
        this.tableDestaques.append(html);
    }
}