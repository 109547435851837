export default class Jogos {
    init() {
        window.Session.isLogged();
        this.tabelaJogos = $('.tabela-jogos');
        this.bind();
    }

    bind() {
        let self = this;
        var request = window.APIService.get('afia-tools/tabela-de-jogos/' + window.Session.getEvento());

        $('.loading').show();
        request.always(function (jogos) {
            $('.loading').hide();
            if (jogos != null && jogos.length > 0) {
                self.renderTabela(jogos);
            } else {
                self.tabelaJogos.append('<table class="table table-hover"><tr><td class="text-center"><i class="bx bxs-error-circle"></i> Nenhum jogo encontrado!</td></tr></table>');
            }
        });
    }

    /**
     * Renderiza a tabela de jogos do campeonato
     * @param {JSON} jogos Json com os jogos
     * @returns {void}
     * 
     */
    renderTabela(jogos) {
        let html = '';

        $.each(jogos, function (index, fases) {
            html += `<p class="text-center fs-3">${fases.descricao}</p><hr>`;

            $.each(fases.dias, function (indexDias, dia) {
                html += `<table class="table table-striped table-hover">
                            <thead>
                                <tr class="text-white bg-primary">
                                    <th colspan="5" class="text-center h4"><i class='bx bx-calendar bx-sm'></i> ${dia.descricao}</th>
                                </tr>
                                <tr class="bg-light text-center">
                                    <th scope="col" width="12%">Horário</th>
                                    <th scope="col" class="text-end"
                                        width="36%">Time A</th>
                                    <th scope="col" width="12%">Placar</th>
                                    <th scope="col" class="text-start"
                                        width="36%">Time B</th>
                                    <th scope="col" width="5%">Grupo</th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider">`;

                $.each(dia.jogos, function (indexJogos, jogo) {
                    let categoriaA = (typeof jogo.time_a.categoria.descricao != 'undefined') ? ' - ' + jogo.time_a.categoria.descricao : '';
                    let categoriaB = (typeof jogo.time_b.categoria.descricao != 'undefined') ? ' - ' + jogo.time_b.categoria.descricao : '';
                    let placar = (jogo.penalti_a > 0 || jogo.penalti_b > 0)
                        ? `${jogo.pontuacao_a}<sup>${jogo.penalti_a}</sup> x <sup>${jogo.penalti_b}</sup>${jogo.pontuacao_b}`
                        : `${jogo.pontuacao_a} x ${jogo.pontuacao_b}`;

                    html += `<tr class="jogo text-center" data-id="${jogo.id_jogo}">
                                <td>${window.Helper.dateToHour(jogo.data_hora)}</td>
                                <td class="text-end">${jogo.time_a.nome_time.nome + categoriaA}</td>
                                <td>${placar}</td>
                                <td class="text-start">${jogo.time_b.nome_time.nome + categoriaB}</td>
                                <td>${jogo.grupo}</td>
                            </tr>`;
                });
                html += `</tbody>
                        </table>`;
            });
        });

        this.tabelaJogos.append(html);
        this.actions();
    }

    actions() {
        $('tr.jogo').on('click', function () {
            let id = $(this).data('id');
            $('.loading').show();
            window.location.href = 'jogo.html?id=' + id;
        });
    }
}