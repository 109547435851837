export default class Helper {

    /**
     * Retorna o valor de um parâmetro da URL
     * @param {string} parametro Parâmetro a ser buscado
     * @returns {string}
     * 
     * @example get('id') // 123
     */
    get(parametro) {
        return new URLSearchParams(window.location.search).get(parametro);
    }

    /**
     * Adiciona zeros a esquerda
     * @param {string} nr Valor a ser adicionado os zeros
     * @param {number} n Quantidade de zeros a ser adicionado
     * @param {string} str Caracter a ser adicionado
     * @returns {string}
     * 
     * @example padLeft(123, 5, '0') // 00123
     */
    padLeft(nr, n, str) {
        return Array(n - String(nr).length + 1).join(str || '0') + nr;
    }

    /**
     * Verifica se o valor é um número
     * @param {string} str Valor a ser verificado
     * @returns {boolean}
     * 
     * @example isNumeric('123') // true
     */
    isNumeric(str) {
        return /^\d+$/.test(str);
    }

    dateToHour(timestamp, type = 'hour') {
        const date = new Date(timestamp);
        const hours = ("0" + date.getHours()).slice(-2);
        const minutes = ("0" + date.getMinutes()).slice(-2);
        if (type == 'hour') {
            return hours + ":" + minutes;
        }
        return ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear() + " " + hours + ":" + minutes;
    }

    /**
     * Converte a primeira letra para maiúscula
     * @param {string} s String a ser convertida
     * @returns {string}
     * 
     * @example ucfirst('teste') // Teste
     */
    ucfirst(s) {
        return s && s[0].toUpperCase() + s.slice(1);
    }

    categoryIcon(category) {
        switch (category) {
            case 'silver':
                return 'bx bx-shield-alt-2';
            case 'platinum':
                return 'bx bx-star';
            case 'gold':
                return 'bx bxs-star';
            case 'diamond':
                return 'bx bx-diamond';
            case 'sapphire':
                return 'bx bxs-diamond';
            default:
                return 'bx bx-shield-alt-2';
        }
    }
}