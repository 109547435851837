/* JQuery */
window.$ = window.jQuery = require('jquery');

/* Bootstrap */
window.bootstrap = require('bootstrap');

/* jQuery Mask Plugin */
import 'jquery-mask-plugin';

/* SweetAlert2 */
window.Swal = require('sweetalert2');

/* Toastr */
window.toastr = require('toastr');
toastr.options.progressBar = true;

/* Páginas */
import Session from './session';
import APIService from './api-service';
import Helper from './helper';
import Login from './login';
import Jogos from './jogos';
import Jogo from './jogo';
import Home from './home';
import Evento from './evento';
import Artilheiros from './artilheiros';
import Cartoes from './cartoes';
import Tabela from './tabela';

window.Session = new Session();
window.APIService = new APIService();
window.Helper = new Helper();
window.Login = new Login();
window.Jogos = new Jogos();
window.Jogo = new Jogo();
window.Home = new Home();
window.Evento = new Evento();
window.Artilheiros = new Artilheiros();
window.Cartoes = new Cartoes();
window.Tabela = new Tabela();

/* Cordova */
import Cordova from './cordova';
window.Cordova = new Cordova();
window.Cordova.init();

jQuery(function () {
    /* Logout */
    if ($('.logout').length) {
        $('.logout').on('click', function () {
            window.Session.destroySession();
            window.location.href = 'index.html';
        });
    }
});