export default class Session {

    createSession(idUsuario, token) {
        sessionStorage.setItem('_LOGGED_', true);
        sessionStorage.setItem('_USER_', idUsuario);
        sessionStorage.setItem('_TOKEN_', token);
    }

    destroySession() {
        sessionStorage.removeItem('_LOGGED_');
        sessionStorage.removeItem('_USER_');
        sessionStorage.removeItem('_EVENTO_');
        sessionStorage.removeItem('_TOKEN_');
    }

    setEvento(idEvento) {
        sessionStorage.setItem('_EVENTO_', idEvento);
    }

    getEvento() {
        return sessionStorage.getItem('_EVENTO_');
    }

    getUser() {
        return sessionStorage.getItem('_USER_');
    }

    getToken() {
        return sessionStorage.getItem('_TOKEN_');
    }

    isLogged(login) {
        if (sessionStorage.getItem('_LOGGED_') == null) {
            if (!login) {
                window.location.href = '/index.html';
            }
        } else {
            if (login) {
                window.location.href = '/home.html';
            }
        }
    }
}