export default class Tabela {
    init() {
        window.Session.isLogged();
        this.tabelaClassificacao = $('.tabela-classificacao');
        this.bind();
    }

    bind() {
        let self = this;
        var request = window.APIService.get('esportivo/tabela-de-jogos/' + window.Session.getEvento());

        $('.loading').show();
        request.always(function (tabelas) {
            $('.loading').hide();
            if (tabelas.ultima_atualizacao) {
                self.renderTabela(tabelas);
            } else {
                self.tabelaClassificacao.append('<table class="table table-hover"><tr><td class="text-center"><i class="bx bxs-error-circle"></i> Nenhum jogo encontrado!</td></tr></table>');
            }
        });
    }

    /**
     * Renderiza a tabela de jogos do campeonato
     * @param {JSON} jogos Json com os jogos
     * @returns {void}
     * 
     */
    renderTabela(tabelas) {
        let html = '';

        $.each(tabelas, function (index, categoria) {
            if (index != 'ultima_atualizacao') {
                html += `<br><p class="text-center fs-1"><i class='${window.Helper.categoryIcon(index)}'></i> ${window.Helper.ucfirst(index)}</p><hr>`;

                $.each(categoria, function (indexFases, fases) {

                    html += `<table class="table table-striped table-hover">
                                <thead>
                                    <tr class="text-white bg-primary">
                                        <th colspan="13" class="text-start h4"><i class='bx bx-tag-alt'></i> Grupo ${window.Helper.ucfirst(indexFases)}</th>
                                    </tr>
                                    <tr class="bg-light text-center">
                                        <th scope="col" class="text-start" colspan="2">Classificação</th>
                                        <th scope="col" width="6%" data-toggle="tooltip" data-placement="top" title="Pontos">P</th>
                                        <th scope="col" width="6%" data-toggle="tooltip" data-placement="top" title="Jogos">J</th>
                                        <th scope="col" width="6%" data-toggle="tooltip" data-placement="top" title="Vitórias">V</th>
                                        <th scope="col" width="6%" data-toggle="tooltip" data-placement="top" title="Empates">E</th>
                                        <th scope="col" width="6%" data-toggle="tooltip" data-placement="top" title="Derrotas">D</th>
                                        <th scope="col" width="6%" data-toggle="tooltip" data-placement="top" title="Gols Pro">GP</th>
                                        <th scope="col" width="6%" data-toggle="tooltip" data-placement="top" title="Gols Contra">GC
                                        </th>
                                        <th scope="col" width="6%" data-toggle="tooltip" data-placement="top" title="Saldo de Gols">SG
                                        </th>
                                        <th scope="col" width="6%" data-toggle="tooltip" data-placement="top"
                                            title="Cartão Vermelho">CV</th>
                                        <th scope="col" width="6%" data-toggle="tooltip" data-placement="top" title="Cartão Azul">CA
                                        </th>
                                        <th scope="col" width="6%" data-toggle="tooltip" data-placement="top" title="Cartão Amarelo">
                                            CA</th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider text-center">`;

                    var posicao = 1;
                    $.each(fases.classificacao, function (indexTime, time) {
                        html += `<tr class="align-middle">
                                    <th width="5%">${posicao}</th>
                                    <td class="text-start">${time.nome}</td>
                                    <td>${time.pontos}</td>
                                    <td>${time.jogos}</td>
                                    <td>${time.vitorias}</td>
                                    <td>${time.empates}</td>
                                    <td>${time.derrotas}</td>
                                    <td>${time.gols_pro}</td>
                                    <td>${time.gols_contra}</td>
                                    <td>${time.saldo_gols}</td>
                                    <td><span class="fw-bold text-danger">${time.cartao_vermelho}</span></td>
                                    <td><span class="fw-bold text-primary">${time.cartao_azul}</span></td>
                                    <td><span class="fw-bold text-warning">${time.cartao_amarelo}</span></td>
                                </tr>`;
                        posicao++;
                    });

                    html += `   </tbody>
                            </table><br>`;

                });
            }
        });
        
        this.tabelaClassificacao.append(html);
        $('[data-toggle="tooltip"]').tooltip();
    }
}