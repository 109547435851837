export default class Artilheiros {
    init() {
        window.Session.isLogged();
        this.tableDestaques = $('.table-destaques');
        this.bind();
    }

    bind() {
        let self = this;

        /* Listar destaques */
        $('.loading').show();
        var request = window.APIService.get('afia-tools/gols/' + window.Session.getEvento());
        request.always(function (artilheiros) {
            $('.loading').hide();
            if (artilheiros != null && artilheiros.length > 0) {
                self.renderDestaques(artilheiros);
            } else {
                self.tableDestaques.append('<tr><td colspan="4" class="text-center"><i class="bx bxs-error-circle"></i> Nenhum artilheiro encontrado!</td></tr>');
            }
        });
    }

    /**
     * Renderiza os artilheiros na tabela
     * @param {JSON} artilheiros Json com os artilheiros
     * @returns {void}
     * 
     */
    renderDestaques(artilheiros) {
        let html = '';
        $.each(artilheiros, function (index, artilheiro) {
            html += '<tr>';
            html += '<td>' + (index + 1) + '</td>';
            html += '<td>' + artilheiro.jogador.pessoa.nome + '</td>';
            html += '<td>' + artilheiro.time.nome_time.nome + ' - ' + artilheiro.time.categoria.descricao + '</td>';
            html += '<td class="text-center">' + artilheiro.quantidade + '</td>';
            html += '</tr>';
        });
        this.tableDestaques.append(html);
    }
}